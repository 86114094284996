import Flickity from "flickity";
// import { gsap } from "gsap";

const slideshow = () => {
  const elements = document.querySelectorAll(".block.block--slideshow");
  if (!elements) return;
    elements.forEach((element) => {
      const flkty = new Flickity(element, {
      imagesLoaded: false,
      initialIndex: '.slide',
      wrapAround: true,
      prevNextButtons: false,
      autoPlay: 5000,
      accessibility: true,
      pageDots: false
    });

    return flkty;
  })
}

export default slideshow;