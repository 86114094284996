let mql = window.matchMedia('(min-width: 991px)');

const pagePortal = () => {
  const elements = document.querySelectorAll(".block--page-portal");

  function onLoadFunction(e) {
    onResizeFunction();
    window.addEventListener("resize", onResizeFunction);
  }

  function onResizeFunction(e) {
    if (mql.matches) {
      elements.forEach((element) => {
        const cardsContainer = element.querySelector('.block__content');
        const cards = element.querySelectorAll('.card');
        const firstCard = cards[0];
        const firstCardBackgroundImageURL = firstCard.dataset.background;
        if (!firstCardBackgroundImageURL) return;
        cardsContainer.style.backgroundImage = `url(${firstCardBackgroundImageURL})`;

        cards.forEach((card) => {
          card.addEventListener('mouseenter', (e) => {
            const target = e.target;
            const backgroundImageURL = target.dataset.background;
            if (!backgroundImageURL) return;
            cardsContainer.style.backgroundImage = `url(${backgroundImageURL})`;
          })
        })
      });
    }
  }

  onLoadFunction();
}

export default pagePortal;