import toggleState from "../utils/toggleState";
let mql = window.matchMedia('(max-width: 991px)');

const toggleMenu = (e) => {
  e.preventDefault();
  toggleState('.nav--button', 'closed', 'open');
  toggleState('nav#nav-main', 'closed', 'open');
  toggleState('body', 'noscroll', 'scroll');
}

const menuToggle = () => {
  function onLoadFunction(e) {
    onResizeFunction();
    window.addEventListener("resize", onResizeFunction);
  }

  onLoadFunction();

  function onResizeFunction(e) {

    const navButton = document.querySelector('.nav--button');
    const navMain = document.querySelector('nav#nav-main');
    const body = document.querySelector('body');

    if (mql.matches) {
      const menuButton = document.querySelector('.nav--button');
      if (menuButton) {
        menuButton.addEventListener('click', toggleMenu);
      }
    } else {
      console.log('desktop');
      if (navButton) {
        navButton.setAttribute('data-state', 'closed');
      }
      if (navMain) {
        navMain.setAttribute('data-state', 'closed');
      }
      body.setAttribute('data-state', 'scroll');
    }
  }
}

export default menuToggle;