const teamFiltering = () => {
  const elements = document.querySelectorAll('.block.block--team');

  if (!elements) return;

  elements.forEach( (element) => {
    const ui = element.querySelector('.block__header ul');
    const select = element.querySelector('.block__header select');
    const buttons = ui.querySelectorAll('li');
    const grid = element.querySelector('.block__content');
    const cards = grid.querySelectorAll('.member');

    buttons.forEach( (button) => {
      button.addEventListener('click', (e) => {
        const target = e.target;
        const selectedFilter = ui.querySelector('li.selected');

        if (selectedFilter) {
          selectedFilter.classList.remove('selected');
          target.classList.add('selected');
        }

        const filterValue = e.target.dataset.team;
        const cardsToHide = grid.querySelectorAll(`.member:not(${filterValue})`);
        const hiddenCards = grid.querySelectorAll('.member.hide');
        if (filterValue !== 'all') {
          hiddenCards.forEach( card => card.classList.remove('hide'));
          cardsToHide.forEach( card => card.classList.add('hide'));
        } else {
          cardsToHide.forEach( card => card.classList.remove('hide'));
        }
      })
    })

    select.addEventListener('change', (e) => {
      const filterValue = e.target.value;
      console.log(filterValue);
      const cardsToHide = grid.querySelectorAll(`.member:not(${filterValue})`);
      const hiddenCards = grid.querySelectorAll('.member.hide');
      if (filterValue !== 'Show All') {
        hiddenCards.forEach( card => card.classList.remove('hide'));
        cardsToHide.forEach( card => card.classList.add('hide'));
      } else {
        cardsToHide.forEach( card => card.classList.remove('hide'));
      }
    })
  });
}

export default teamFiltering;