import Plyr from "plyr";

// const videoPlyrs = Plyr.setup('.plyr');

const videoPlayer = () => {
  const players = Array.from(document.querySelectorAll('.js-player')).map((p) => new Plyr(p, {
    controls: ['play-large'],
    disableContextMenu: false
  }));

  console.log('Players', players);
}

export default videoPlayer;