const caseStudyFiltering = () => {
    const filterButtons = document.querySelectorAll('.filter-button');
    const filterOptions = document.querySelectorAll('.filter-options');
    let selectedService = null;
    let selectedIndustry = null;
    let hasSelectedFilter = false;

    const entryId = document.querySelector('meta[name="entry-id"]').content;
    const blockId = document.querySelector('meta[name="block-id"]').content;

    // Load selected options from URL parameters
    const urlParams = new URLSearchParams(window.location.search);
    const servicesFromURL = urlParams.get('services');
    const industriesFromURL = urlParams.get('industries');

    // Pre-select the options based on URL parameters
    if (servicesFromURL) {
        const option = document.querySelector(`.filter-option[data-slug="${servicesFromURL}"]`);
        if (option) {
            option.classList.add('selected');
            updateButton(option);
            selectedService = servicesFromURL;
            hasSelectedFilter = true;  // Indicates a filter is selected
        }
    }

    if (industriesFromURL) {
        const option = document.querySelector(`.filter-option[data-slug="${industriesFromURL}"]`);
        if (option) {
            option.classList.add('selected');
            updateButton(option);
            selectedIndustry = industriesFromURL;
            hasSelectedFilter = true;  // Indicates a filter is selected
        }
    }

    // If a filter is selected, load filtered case studies; otherwise, use initial data
    if (hasSelectedFilter) {
        loadCaseStudies(selectedService, selectedIndustry);
    } 

    filterButtons.forEach((button, index) => {
        const options = filterOptions[index];
        button.setAttribute('aria-expanded', 'false');
        button.setAttribute('aria-controls', `filter-options-${index}`);
        button.setAttribute('role', 'button');
        button.setAttribute('tabindex', '0');

        options.setAttribute('id', `filter-options-${index}`);
        options.setAttribute('role', 'listbox');
    });

    filterButtons.forEach(button => {
        button.addEventListener('click', function () {
            toggleDropdown(button);
        });

        button.addEventListener('keydown', function (event) {
            if (event.key === 'Enter' || event.key === ' ') {
                event.preventDefault();
                toggleDropdown(button);
            }
        });

        button.addEventListener('blur', function () {
            setTimeout(() => {
                if (!button.contains(document.activeElement) && !button.nextElementSibling.contains(document.activeElement)) {
                    closeDropdown(button);
                }
            }, 10);
        });
    });

    document.querySelectorAll('.filter-option').forEach(option => {
        option.setAttribute('role', 'option');
        option.setAttribute('tabindex', '0');

        option.addEventListener('click', function () {
            selectOption(option);
        });

        option.addEventListener('keydown', function (event) {
            handleOptionKeydown(event, option);
        });

        option.addEventListener('blur', function () {
            setTimeout(() => {
                const button = option.closest('.filter-dropdown').querySelector('.filter-button');
                if (!option.closest('.filter-options').contains(document.activeElement)) {
                    closeDropdown(button);
                }
            }, 10);
        });
    });

    function toggleDropdown(button) {
        const arrow = button.querySelector('.filter-arrow svg');
        const options = document.getElementById(button.getAttribute('aria-controls'));
        const isOpen = button.getAttribute('aria-expanded') === 'true';

        filterOptions.forEach(opt => {
            opt.style.display = 'none';
            opt.previousElementSibling.setAttribute('aria-expanded', 'false');
            opt.previousElementSibling.classList.remove('active');
        });

        if (isOpen) {
            closeDropdown(button);
        } else {
            options.style.display = 'block';
            button.setAttribute('aria-expanded', 'true');
            button.classList.add('active');
            arrow.innerHTML = `<svg width="15" height="8" viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.6218 7.31079L7.31092 0.999885L1.00002 7.31079" stroke="#AEC93B" stroke-opacity="0.5" stroke-width="1.26218"/>
            </svg>`;
        }
    }

    function closeDropdown(button) {
        const options = document.getElementById(button.getAttribute('aria-controls'));
        options.style.display = 'none';
        button.setAttribute('aria-expanded', 'false');
        button.classList.remove('active');
        resetArrows();
    }

    function selectOption(option) {
        const slug = option.dataset.slug;
        const isService = option.parentElement.getAttribute('data-type') === 'services';

        // Deselect previously selected options in the other dropdown
        if (isService) {
            if (selectedIndustry) {
                document.querySelector(`.filter-option[data-slug="${selectedIndustry}"]`)?.classList.remove('selected');
                selectedIndustry = null;
                document.getElementById('industryFilterButton').querySelector('.filter-button-text').textContent = 'All Industries';
            }
            selectedService = slug;
        } else {
            if (selectedService) {
                document.querySelector(`.filter-option[data-slug="${selectedService}"]`)?.classList.remove('selected');
                selectedService = null;
                document.getElementById('serviceFilterButton').querySelector('.filter-button-text').textContent = 'All Services';
            }
            selectedIndustry = slug;
        }

        if (option.classList.contains('selected')) {
            option.classList.remove('selected');
            updateButton(option, true);
            if (isService) {
                selectedService = null;
            } else {
                selectedIndustry = null;
            }
        } else {
            option.parentElement.querySelectorAll('.filter-option.selected').forEach(opt => opt.classList.remove('selected'));
            option.classList.add('selected');
            updateButton(option);
        }

        updateURL(selectedService, selectedIndustry);
        loadCaseStudies(selectedService, selectedIndustry);
        closeDropdown(option.closest('.filter-dropdown').querySelector('.filter-button'));
    }

    function updateButton(option, reset = false) {
        const button = option.closest('.filter-dropdown').querySelector('.filter-button .filter-button-text');
        const fullTitle = option.dataset.title;
        const screenWidth = window.innerWidth;

        if (reset) {
            button.textContent = option.parentElement.getAttribute('data-type') === 'services' ? 'All Services' : 'All Industries';
        } else if (screenWidth < 767) {
            const shortTitle = fullTitle.split(' ')[0].replace(',', '');
            button.textContent = shortTitle.length < fullTitle.length ? `${shortTitle}...` : shortTitle;
        } else {
            button.textContent = fullTitle;
        }
    }

    function handleOptionKeydown(event, option) {
        const dropdown = option.closest('.filter-options');
        const options = dropdown.querySelectorAll('.filter-option');
        const index = Array.prototype.indexOf.call(options, option);

        switch (event.key) {
            case 'ArrowDown':
                event.preventDefault();
                options[(index + 1) % options.length].focus();
                break;
            case 'ArrowUp':
                event.preventDefault();
                options[(index - 1 + options.length) % options.length].focus();
                break;
            case 'Enter':
            case ' ':
                event.preventDefault();
                selectOption(option);
                break;
            case 'Escape':
                event.preventDefault();
                const button = option.closest('.filter-dropdown').querySelector('.filter-button');
                closeDropdown(button);
                button.focus();
                break;
        }
    }

    function updateURL(service, industry) {
        const url = new URL(window.location);
        if (service) {
            url.searchParams.set('services', service);
        } else {
            url.searchParams.delete('services');
        }

        if (industry) {
            url.searchParams.set('industries', industry);
        } else {
            url.searchParams.delete('industries');
        }

        window.history.replaceState(null, '', url.toString());
    }

    function loadCaseStudies(service, industry) {
        updateURL(service, industry);

        const serviceParam = service ? service : '';
        const industryParam = industry ? industry : '';

        fetch(`/components/blocks/partials/case-studies-json?entryId=${entryId}&blockId=${blockId}&services=${serviceParam}&industries=${industryParam}`)
            .then(response => response.json())
            .then(data => {
                const caseStudiesContainer = document.getElementById('caseStudiesContainer');

                if (data.caseStudies.length > 0) {
                    caseStudiesContainer.innerHTML = '';

                    data.caseStudies.forEach(caseStudy => {
                        const articleCard = document.createElement('a');
                        articleCard.className = 'article-card';
                        articleCard.href = caseStudy.url;

                        const imageHTML = `
                        <picture>
                            <source srcset="${caseStudy.images.desktop}" sizes="(min-width: 992px) 50vw" media="(min-width: 992px)" />
                            <img src="${caseStudy.images.mobile}" alt="${caseStudy.title}" class="banner__media" />
                        </picture>
                    `;

                        articleCard.innerHTML = `
                        <div class="article-image">
                            ${imageHTML}
                            <div class="arrow"></div>
                        </div>
                        <div class="article-content">
                            <div class="article-meta">
                                ${caseStudy.serviceTitle ? caseStudy.serviceTitle : ''}
                            </div>
                            <h3 class="article-title">${caseStudy.title}</h3>
                            <span class="read-more">Read Full Case Study &gt;</span>
                        </div>
                    `;

                        caseStudiesContainer.appendChild(articleCard);
                    });
                } else {
                    caseStudiesContainer.innerHTML = `
                    <div class="no-results">No relevant case studies found. Consider modifying your selections.</div>
                `;
                }
            })
            .catch(error => console.error('Error:', error));
    }

    function resetArrows() {
        document.querySelectorAll('.filter-arrow svg').forEach(arrow => {
            arrow.innerHTML = `<svg width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L7.31091 7.31091L13.6218 1" stroke="#9B9581" stroke-width="1.26218"/>
            </svg>`;
        });
    }

    // Handle screen resize to update button text
    window.addEventListener('resize', () => {
        document.querySelectorAll('.filter-option.selected').forEach(option => {
            updateButton(option);
        });
    });

    // Close dropdown if clicking outside of it or pressing the Escape key
    document.addEventListener('click', function (event) {
        if (!event.target.closest('.filter-dropdown')) {
            filterOptions.forEach(options => {
                options.style.display = 'none';
            });
            filterButtons.forEach(button => button.setAttribute('aria-expanded', 'false'));
            resetArrows();
        }
    });

    document.addEventListener('keydown', function (event) {
        if (event.key === 'Escape') {
            filterOptions.forEach(options => {
                options.style.display = 'none';
            });
            filterButtons.forEach(button => {
                button.setAttribute('aria-expanded', 'false');
                button.classList.remove('active');
            });
            resetArrows();
        }
    });
};

export default caseStudyFiltering;
