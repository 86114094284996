const mapDots = () => {
    const mapDots = document.querySelectorAll('.map-dot');

    mapDots.forEach(dot => {
        dot.addEventListener('mouseenter', function () {
            const locationId = dot.id.replace('dot-', '');
            const correspondingText = document.querySelector(`.location-details[data-location="${locationId}"] strong`);

            if (locationId === 'calgary') {
                dot.style.transformOrigin = '123px 249px';
            } else if (locationId === 'houston') {
                dot.style.transformOrigin = '170px 308px';
            } else if (locationId === 'vienna') {
                dot.style.transformOrigin = '415px 250px';
            } else if (locationId === 'bruce-county') {
                dot.style.transformOrigin = '193px 258px';
            }

            // Find the first child with a valid fill color from either 'path' or 'rect'
            let fillColor = null;
            dot.querySelectorAll('path, rect').forEach(shape => {
                const color = shape.getAttribute('fill');
                if (color && (color.toLowerCase() === '#aec93b' || color.toLowerCase() === '#61b249')) {
                    fillColor = color;
                }
            });

            if (correspondingText && fillColor) {
                correspondingText.style.color = fillColor;
                dot.classList.add('active');
            }
        });

        dot.addEventListener('mouseleave', function () {
            const locationId = dot.id.replace('dot-', '');
            const correspondingText = document.querySelector(`.location-details[data-location="${locationId}"] strong`);
            if (correspondingText) {
                correspondingText.style.color = '';
                dot.classList.remove('active');
            }
        });
    });
};

export default mapDots;
