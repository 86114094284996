import toggleState from "../utils/toggleState";

let mql = window.matchMedia('(max-width: 991px)');

const mobileNavigation = () => {
  function onLoadFunction(e) {
    onResizeFunction();
    window.addEventListener("resize", onResizeFunction);
  }

  onLoadFunction();
  
  function onResizeFunction(e) {
    if (mql.matches) {
      const navigationElements = document.querySelectorAll('#nav-main > ul:first-of-type > li.has__sub__menu');
      navigationElements.forEach((item) => {
        item.addEventListener('click', (e) => {
          e.stopPropagation();
          const target = e.target.parentNode;
          if (target.getAttribute('data-state') == 'closed') {
            target.setAttribute('data-state', 'open')
          } else {
            target.setAttribute('data-state', 'closed');
          }
        })
      })
    }
  }
}

export default mobileNavigation;